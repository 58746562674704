import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSelections } from "../../audience-selector/audienceSelector.service";
import {
  setAdhocSms,
  switchModal,
} from "../../../redux/adhoc-communication/adhoc-communication.reducer";
import {
  getSMSFields,
  getCampaignNameList,
  getProductGroupList,
  getProductBrandsList,
  createBitly,
  sendTestSMSBroadcast,
} from "../adhocCommunication.service";
import { useIsAuthenticated } from "@azure/msal-react";
import uuid from "react-uuid";
import ApiNew from "../../../common/api/apinew.service";

const useTriggeredSmsModal = () => {
  const { api } = ApiNew();

  const reduxSelectedAudience = useSelector(
    ({ adhocCommunication: { selectedAudience } }) => selectedAudience,
  );
  const reduxAdhocSms = useSelector(
    ({ adhocCommunication: { adhocSms } }) => adhocSms,
  );
  const dispatch = useDispatch();
  const [audienceList, setAudienceList] = useState([]);
  const [selectedAudienceList, setSelectedAudienceList] = useState([]);

  const [fieldPickerList, setFieldPickerList] = useState([]);

  const [productGroupList, setProductGroupList] = useState([]);
  const [productBrandList, setProductBrandList] = useState([]);
  const [campaignNameList, setCampaignNameList] = useState([]);

  const [selectedProductBrand, setSelectedProductBrand] = useState("");
  const [selectedProductGroup, setSelectedProductGroup] = useState("");
  const [selectedCampaignName, setSelectedCampaignName] = useState("");

  const [selectedField, setSelectedField] = useState({ name: "", label: "" });
  const [selectedUrlField, setSelectedUrlField] = useState({
    name: "",
    label: "",
  });

  const [selectedFieldList, setSelectedFieldList] = useState([]);
  const [showFallBackFormPopover, setShowFallbackFormPopover] = useState(false);
  const [selectedRelatesToFields, setSelectedRelatesToFields] = useState([]);

  const [UTM_Source, setUTM_Source] = useState({ name: "", value: "" });
  const [UTM_Medium, setUTM_Medium] = useState({ name: "", value: "" });
  const [UTM_Campaign, setUTM_Campaign] = useState({ name: "", value: "" });
  const [UTM_ID, setUTM_ID] = useState({
    name: "utm_id",
    value: "campaign_id",
  });
  const [pastedUrl, setpastedUrl] = useState("");
  let ampCampaingId = "&utm_id=campaign_id&xcm_ind_id={xcm_ind_id}";
  let queCampaignId = "?utm_id=campaign_id&xcm_ind_id={xcm_ind_id}";
  let placeHolder = "your-url-goes-here";
  let temp_string = "https://";
  let smsPlaceHolder = "{{sms-sh-link}}"
  const urlFields = [
    { id: 1, name: "first_person_id", label: "First Person Id" },
  ];

  //https://stackoverflow.com/questions/12413243/javascript-date-format-like-iso-but-local
  const calculateCorrectDate = () => {
    const offsetMs = new Date().getTimezoneOffset() * 60 * 1000;
    const msLocal = new Date().getTime() - offsetMs;
    const dateLocal = new Date(msLocal);
    const iso = dateLocal.toISOString();
    const isoLocal = iso.slice(0, 19);

    return isoLocal;
  };

  //https://stackoverflow.com/questions/12413243/javascript-date-format-like-iso-but-local
  const calculateCorrectDateWithTimeZone = (dateStr) => {
    const date = new Date(dateStr);

    const fullDT = date.toISOString().slice(0, -8);

    return fullDT;
  };

  const [formValidator, setFormValidator] = useState({
    //Later we can add an errorMessage property here so we can have custom error messages, but for now they have been hard
    //coded in the html
    selectedAudiences: { hasError: false },
    messageName: { hasError: false },
    message: { hasError: false },
    schedule: { hasError: false },
  });
  const [nextButtonIsClicked, setNextButtonIsClicked] = useState(false);

  const [triggeredSms, setTriggeredSMS] = useState({
    messageName: "",
    message: "",
    onwardURL: "",
    relatesTo: "",
    testSendTo: "",
    schedule: calculateCorrectDate(),
    selectedFields: [],
    selectedAudiences: [],
    selectedRelatesToArrObj: [],
  });
  const [loading, setLoading] = useState(false);

  const [messageLimit, setMessageLimit] = useState(160);
  const [messageUnits, setMessageUnits] = useState(1);
  const [isGSM, setIsGSM] = useState(true);
  const [bitlyError, setBitlyError] = useState(false);
  const [bitlyLoading, setBitlyLoading] = useState(false);
  const [formattedUrl, setformattedUrl] = useState("");

  const [customRelatesToValue, setCustomRelatesToValue] = useState("");

  const [, setHasTestSent] = useState(false);
  const [sendTestSMSBtnText, setSendTestSMSBtnText] = useState("Send");

  const isAuthenticated = useIsAuthenticated();

  let formIsValid = () => {
    for (let prop in formValidator) {
      if (formValidator[prop].hasError) {
        return false;
      }
    }

    return true;
  };

  let canSendTest = () => {
    return (
      triggeredSms.messageName &&
      triggeredSms.message &&
      triggeredSms.onwardURL &&
      triggeredSms.schedule &&
      triggeredSms.testSendTo
    );
  };

  let updateUrl = (url) => {
    if (url) {
      let params = new URLSearchParams(url.search);

      if (params.size) {
        
        let params = decodeURIComponent(url.searchParams).replace(/.*&utm_id/, "utm_id")

        setformattedUrl((pastedUrl ? pastedUrl : placeHolder) + (pastedUrl.includes("?") ? "&" : "?") + params)

        setTriggeredSMS({
          ...triggeredSms,
          onwardURL: (pastedUrl ? pastedUrl : placeHolder) + (pastedUrl.includes("?") ? "&" : "?") + params,
        });
      }
    } else {
      setformattedUrl(pastedUrl ? pastedUrl : placeHolder);
      setTriggeredSMS({
        ...triggeredSms,
        onwardURL: pastedUrl ? pastedUrl : placeHolder,
      });
    }
  };

  let checkSavedUTMs = (savedUTMs, campaignId) => {
    let utm_values =
      (savedUTMs.source ? `&utm_source=${savedUTMs.source}` : "") +
      (savedUTMs.medium ? `&utm_medium=${savedUTMs.medium}` : "");

    setUTM_Source({ ...UTM_Source, value: savedUTMs.source });
    setUTM_Medium({ ...UTM_Medium, value: savedUTMs.medium });
    setformattedUrl(
      (pastedUrl ? pastedUrl : placeHolder) +
        (campaignId ? campaignId : "") +
        utm_values,
    );
    setTriggeredSMS({
      ...triggeredSms,
      onwardURL:
        (pastedUrl ? pastedUrl : placeHolder) +
        (campaignId ? campaignId : "") +
        utm_values,
    });
  };

  let checkCampaignId = (campaignId) => {
    setformattedUrl(formattedUrl + campaignId);
    setTriggeredSMS({
      ...triggeredSms,
      onwardURL: formattedUrl + campaignId,
    });
  };

  let handleSaveUTMs = (url) => {
    const newSource = url.searchParams.get("utm_source");
    const newMedium = url.searchParams.get("utm_medium");
    if (newSource || newMedium) {
      localStorage.setItem(
        "UTM",
        `{"source": "${newSource ? newSource : ""}", "medium": "${
          newMedium ? newMedium : ""
        }"}`,
      );
    }

    var selector = document.getElementById("sms-messagebody");
    let newValue =
      selector.value.substring(0, selector.selectionStart) +
      smsPlaceHolder +
      selector.value.substring(selector.selectionEnd, selector.value.length);

    setTriggeredSMS({
      ...triggeredSms,
      message: !triggeredSms.message?.includes(smsPlaceHolder)
        ? newValue
        : triggeredSms.message,
    });
  };

  let setUrl = (url) => {
    setformattedUrl(url);
    setTriggeredSMS({
      ...triggeredSms,
      onwardURL: url,
    });
  };

  let setNewUrl = (name, value, url, temp) => {
    if (value) {
      url.searchParams.set(name, value);
    } else {
      url.searchParams.delete(name);
    }
    let temp_url = decodeURIComponent(url);
    if (temp) {
      let new_url = temp_url.replace(temp, "");
      setUrl(new_url);
    } else {
      setUrl(temp_url);
    }
  };

  let deleteFieldsFromUrl = (url, temp) => {
    url.searchParams.delete(selectedUrlField.name);

    let temp_url = decodeURIComponent(url);
    if (temp) {
      let new_url = temp_url.replace(temp, "");

      setUrl(new_url);
    } else {
      setUrl(temp_url);
    }
  };

  let isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (isAuthenticated && api) {
        const audiences = await getSelections(api);
        setAudienceList(audiences);

        const fields = await getSMSFields(api);
        setFieldPickerList(fields);

        const campaignNames = await getCampaignNameList(api);
        // console.log(campaignNames);
        setCampaignNameList(campaignNames);

        const productGroups = await getProductGroupList(api);
        setProductGroupList(productGroups);

        const productBrands = await getProductBrandsList(api);
        setProductBrandList(productBrands);

        // Fill form fields and drop down with previously inserted values
        if (reduxAdhocSms) {
          setTriggeredSMS({ ...reduxAdhocSms });

          if (reduxAdhocSms.relatesTo) {
            setSelectedRelatesToFields([
              ...reduxAdhocSms.relatesTo.split(" / "),
            ]);
          }
          if (reduxAdhocSms.selectedFields.length) {
            setSelectedFieldList([...reduxAdhocSms.selectedFields]);
          }
        }
      }
      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, api]);

  useEffect(() => {
    if (reduxAdhocSms && reduxAdhocSms.selectedAudiences.length) {
      setSelectedAudienceList([...reduxAdhocSms.selectedAudiences]);
    } else if (reduxSelectedAudience) {
      setSelectedAudienceList([reduxSelectedAudience]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxSelectedAudience]);

  useEffect(() => {
    setTriggeredSMS({
      ...triggeredSms,
      selectedAudiences: selectedAudienceList,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAudienceList]);

  useEffect(() => {
    setTriggeredSMS({
      ...triggeredSms,
      selectedFields: selectedFieldList,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFieldList]);

  useEffect(() => {
    if (selectedRelatesToFields.length) {
      setTriggeredSMS({
        ...triggeredSms,
        relatesTo: selectedRelatesToFields.join(" / "),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRelatesToFields]);

  useEffect(() => {
    if (hasUnicode()) {
      setMessageLimit(70);
      setIsGSM(false);
    } else {
      setMessageLimit(160);
      setIsGSM(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredSms.message]);

  useEffect(() => {
    if (nextButtonIsClicked) {
      updateFormValidator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredSms]);

  useEffect(() => {
    if (formattedUrl) {
      if (!formattedUrl.includes("campaign_id") && formattedUrl.includes("?")) {
        checkCampaignId(ampCampaingId);
      } else if (
        !formattedUrl.includes("campaign_id") &&
        !formattedUrl.includes("?")
      ) {
        checkCampaignId(queCampaignId);
      }
    }
  }, [formattedUrl]);

  useEffect(() => {
    if (formattedUrl) {
      if (isValidUrl(formattedUrl)) {
        let url = new URL(formattedUrl);
        updateUrl(url);
      } else {
        //console.log("temp_string", temp_string);
        //console.log("formattedUrl", formattedUrl);
        let temp_url = temp_string + formattedUrl;
        //console.log("temp_url", temp_url);
        let url = new URL(temp_url);
        updateUrl(url);
      }
    } else {
      if (!formattedUrl && JSON.parse(localStorage["UTM"] === undefined)) {
        updateUrl();
      } else if (
        !formattedUrl &&
        JSON.parse(localStorage["UTM"] != undefined)
      ) {
        const savedUTMs = JSON.parse(localStorage["UTM"]);

        if (
          !formattedUrl.includes("campaign_id") &&
          formattedUrl.includes("?")
        ) {
          checkSavedUTMs(savedUTMs, ampCampaingId);
        } else if (
          !formattedUrl.includes("campaign_id") &&
          !formattedUrl.includes("?")
        ) {
          checkSavedUTMs(savedUTMs, queCampaignId);
        } else {
          checkSavedUTMs(savedUTMs);
        }
      }
    }
  }, [pastedUrl]);

  useEffect(() => {
    if (formattedUrl) {
      setUTM_Campaign({
        name: "utm_campaign",
        value: triggeredSms.messageName.replace(/\s/g, "_"),
      });
      appendUTMs("utm_campaign", triggeredSms.messageName.replace(/\s/g, "_"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredSms.messageName]);

  let handleRelatesToChange = (event) => {
    setSelectedRelatesToFields([]);
    handleTriggeredSMSChange(event);
  };

  let handleTextareaChange = (event) => {
    const { value } = event.target;
    let newSelectedField = [...selectedFieldList];

    fieldPickerList.forEach((field) => {
      const textAreaField = `{{${field.name}}}`;
      const selectedField = newSelectedField.find(
        (f) => f.label === field.label,
      );

      if (value.includes(textAreaField)) {
        //If the text area value includes a field then add that field to the selectedfield list if it's not already there.
        // Added name property to field object
        if (!selectedField) {
          let newField = {
            id: uuid(),
            name: field.name,
            label: field.label,
            fallBackValue: "",
          };
          newSelectedField.push(newField);
        }
        //
      } else {
        //If the text area value does not include a field then remove field from the seleced field list if it is there
        if (selectedField) {
          newSelectedField = [
            ...newSelectedField.filter((f) => f.id !== selectedField.id),
          ];
        }
      }
    });

    setSelectedFieldList([...newSelectedField]);

    calculateMessageUnits(value);

    handleTriggeredSMSChange(event);
  };
  
  const fieldchange = (event) => {
    // added name property to field object
    const filter = fieldPickerList.find(
      (field) => field.label === event.target.value,
    );
    const Name = filter.name;
    setSelectedField({ name: Name, label: event.target.value });
  };

  const fieldchange_url = (event, list) => {
    // added name property to field object
    const localfieldpicker = list;
    const filter = localfieldpicker.find(
      (field) => field.label === event.target.value,
    );
    const Name = filter.name;
    setSelectedUrlField({ name: Name, label: event.target.value });
  };

  const calculateMessageUnits = (characters) => {
    const messageLimitValue = messageLimit;
    const currentLength = characters.split("").length;
    let units = Math.ceil(currentLength / messageLimitValue);

    if (units <= 0) {
      units = 1;
    }

    setMessageUnits(units);
  };

  let handleTriggeredSMSChange = (event) => {
    let { name, value } = event.target;

    setTriggeredSMS({
      ...triggeredSms,
      [name]: value,
    });
  };

  let appendUTMs = (name, value) => {
    let utm_name = name;
    let utm_value = value;
    if (isValidUrl(formattedUrl)) {
      let url = new URL(formattedUrl);
      setNewUrl(utm_name, utm_value, url);
    } else {
      //console.log("temp_string", temp_string);
      //console.log("formattedUrl", formattedUrl);
      let temp_url = temp_string + formattedUrl;
      //console.log("temp_url", temp_url);
      let url = new URL(temp_url);
      setNewUrl(utm_name, utm_value, url, temp_string);
    }
  };

  let removefromURL = () => {
    if (isValidUrl(formattedUrl)) {
      let url = new URL(formattedUrl);
      deleteFieldsFromUrl(url);
    } else {
      //console.log("temp_string", temp_string);
      //console.log("formattedUrl", formattedUrl);
      let temp_url = temp_string + formattedUrl;
      //console.log("temp_url", temp_url);
      let url = new URL(temp_url);
      deleteFieldsFromUrl(url, temp_string);
    }
  };

  let appendtoURL = () => {
    let add;
    if (formattedUrl.includes("?")) {
      add = `&${selectedUrlField.name}=`;
    } else {
      add = `?${selectedUrlField.name}=`;
    }
    setTriggeredSMS({
      ...triggeredSms,
      onwardURL: triggeredSms.onwardURL + add + `{${selectedUrlField.name}}`,
    });
    setformattedUrl(formattedUrl + add + `{${selectedUrlField.name}}`);
    console.log(triggeredSms);
  };

  let appendSelectedField = () => {
    var selector = document.getElementById("sms-messagebody");
    let newValue =
      selector.value.substring(0, selector.selectionStart) +
      `{{${selectedField.name}}}` +
      selector.value.substring(selector.selectionEnd, selector.value.length);

    setTriggeredSMS({
      ...triggeredSms,
      message: newValue,
    });

    const existingField = selectedFieldList.find(
      (field) => field.label === selectedField.label,
    );
    if (existingField) {
      return;
    }
    // added name property to field object
    let newField = {
      id: uuid(),
      name: selectedField.name,
      label: selectedField.label,
      fallBackValue: "",
    };

    setSelectedFieldList(selectedFieldList.concat(newField));
  };

  let removeSelectedField = (fieldItem) => {
    //Remove the selected field label from the message textarea
    const textAreaField = `{{${fieldItem.name}}}`;
    if (triggeredSms.message.includes(textAreaField)) {
      const newMessage = triggeredSms.message.replaceAll(textAreaField, "");
      setTriggeredSMS({
        ...triggeredSms,
        message: newMessage,
      });
    }

    setSelectedFieldList([
      ...selectedFieldList.filter((field) => field.id !== fieldItem.id),
    ]);
  };

  let handleFallbackFormInputChange = (event, fieldItem) => {
    const { value } = event.target;

    const updatedListValue = selectedFieldList.map((field) => {
      if (field.id === fieldItem.id) {
        return {
          ...field,
          fallBackValue: value,
        };
      }

      return field;
    });

    setSelectedFieldList(updatedListValue);
  };

  let appendSelectedProductBrand = () => {
    if (selectedProductBrand) {
      if (typeof selectedProductBrand === "string") {
        setSelectedRelatesToFields([
          ...selectedRelatesToFields,
          `Brand: ${selectedProductBrand}`,
        ]);

        const triggeredSmsObj = { ...triggeredSms };
        triggeredSmsObj.selectedRelatesToArrObj = [
          ...triggeredSmsObj.selectedRelatesToArrObj,
          { Brand: `${selectedProductBrand}` },
        ];

        setTriggeredSMS({ ...triggeredSmsObj });
      } else {
        const label = selectedProductBrand.label;
        setSelectedRelatesToFields([
          ...selectedRelatesToFields,
          `Brand: ${label}`,
        ]);

        const triggeredSmsObj = { ...triggeredSms };
        triggeredSmsObj.selectedRelatesToArrObj = [
          ...triggeredSmsObj.selectedRelatesToArrObj,
          { Brand: `${label}` },
        ];

        setTriggeredSMS({ ...triggeredSmsObj });
      }
    }
  };

  let appendSelectedProductGroup = () => {
    if (selectedProductGroup) {
      if (typeof selectedProductGroup === "string") {
        setSelectedRelatesToFields([
          ...selectedRelatesToFields,
          `Group: ${selectedProductGroup}`,
        ]);
      }
      const label = selectedProductGroup.label;
      setSelectedRelatesToFields([
        ...selectedRelatesToFields,
        `Group: ${label}`,
      ]);
    }
  };

  let appendSelectedCampaignName = () => {
    if (selectedCampaignName) {
      if (typeof selectedCampaignName === "string") {
        setSelectedRelatesToFields([
          ...selectedRelatesToFields,
          `Campaign: ${selectedCampaignName}`,
        ]);
      } else {
        setSelectedRelatesToFields([
          ...selectedRelatesToFields,
          `Campaign: ${selectedCampaignName.label}`,
        ]);
      }
    }
  };

  const appendCustomRelatesToField = () => {
    const customField = customRelatesToValue;

    setSelectedRelatesToFields([
      ...selectedRelatesToFields,
      `Custom Field: ${customField}`,
    ]);

    setCustomRelatesToValue("");
  };

  let handleformattedUrlChange = (event) => {
    let { name, value } = event.target;
    setTriggeredSMS({
      ...triggeredSms,
      [name]: value,
    });
    setformattedUrl(value);
    // setBitlyError(false);
  };

  let createBitlyLink = async () => {
    setBitlyError(false);
    setBitlyLoading(true);
    const response = await createBitly(formattedUrl);

    if (response.status !== 201 || !response.data?.link) {
      setBitlyError(true);
    } else {
      setTriggeredSMS({
        ...triggeredSms,
        message: triggeredSms.message + ` ${response.data.link}`,
      });
    }

    setBitlyLoading(false);
  };

  let hasUnicode = () => {
    const length = triggeredSms.message.length;

    for (let i = 0; i < length; i++) {
      if (triggeredSms.message.charCodeAt(i) > 225) {
        return true;
      }
    }

    return false;
  };

  let onCheckBoxChange = (audienceId) => {
    if (selectedAudienceList.some((aud) => aud.id === audienceId)) {
      const filteredList = selectedAudienceList.filter(
        (aud) => aud.id !== audienceId,
      );
      setSelectedAudienceList(filteredList);
    } else {
      const audience = audienceList.find((aud) => aud.id === audienceId);
      setSelectedAudienceList(selectedAudienceList.concat(audience));
    }
  };

  let nextModal = () => {
    setNextButtonIsClicked(true);
    const validator = updateFormValidator();

    const keys = Object.keys(validator);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (validator[key].hasError) {
        //This would stop the function if any of the form fields is empty (doesn't have a value), so the user won't be taken to the next modal
        return;
      }
    }

    dispatch(setAdhocSms(triggeredSms));
    dispatch(switchModal(2));
  };

  const updateFormValidator = () => {
    setLoading(true);

    const validator = Object.assign({}, formValidator);

    validator.selectedAudiences.hasError =
      triggeredSms.selectedAudiences.length === 0 ? true : false;
    validator.messageName.hasError =
      triggeredSms.messageName === "" ? true : false;
    validator.message.hasError = triggeredSms.message === "" ? true : false;
    validator.schedule.hasError = triggeredSms.schedule === "" ? true : false;

    setFormValidator(validator);
    setLoading(false);

    return validator;
  };

  let prevModal = () => {
    dispatch(setAdhocSms(triggeredSms));
    dispatch(switchModal(0));
  };

  const customRelatesToInputOnChange = (event) => {
    const newValue = event.target.value;

    setCustomRelatesToValue(newValue);
  };

  let sendTestSMSToOnChange = (event) => {
    const sendTo = event.target.value;

    let triggeredSmsObj = { ...triggeredSms };
    triggeredSmsObj.testSendTo = sendTo;

    setTriggeredSMS(triggeredSmsObj);
  };

  const createRelatesToJSON = (relatesTo) => {
    let obj = {
      customFields: [],
      campaigns: [],
      brands: [],
      groups: [],
    };

    relatesTo.split(" / ").forEach((str) => {
      const strArr = str.split(":");
      let fieldName = strArr[0].toLowerCase();
      let value = strArr[1] ? strArr[1].trim() : "";

      switch (fieldName) {
        case "group":
          obj.groups.push(value);
          break;

        case "custom field":
          obj.customFields.push(value);
          break;

        case "campaign":
          obj.campaigns.push(value);
          break;

        case "brand":
          obj.brands.push(value);
          break;

        default:
          break;
      }
    });

    let relatesToObj = {
      relatesTo: obj,
    };

    return JSON.stringify(relatesToObj);
  };

  const removeUrl = () => {
    setTriggeredSMS({
      ...triggeredSms,
      message: triggeredSms.message?.includes(smsPlaceHolder)
        ? triggeredSms.message.replace(smsPlaceHolder, "")
        : triggeredSms.message,
    });

    console.log(triggeredSms);
  };

  const appendUrl = () => {
    console.log(triggeredSms);
    if (
      isValidUrl(triggeredSms.onwardURL) &&
      !triggeredSms.onwardURL.includes(placeHolder)
    ) {
      const url = new URL(triggeredSms.onwardURL);
      handleSaveUTMs(url);
      console.log(triggeredSms);
    } else if (
      !isValidUrl(triggeredSms.onwardURL) &&
      !triggeredSms.onwardURL.includes(placeHolder)
    ) {
      let temp_url = temp_string + triggeredSms.onwardURL;
      const url = new URL(temp_url);
      handleSaveUTMs(url);
      console.log(triggeredSms);
    }
  };

  let sendTestSMS = async () => {
    const audienceIDs = triggeredSms.selectedAudiences.map((x) => x.id);

    let testSendSMSBody = {
      SendTo: triggeredSms.testSendTo,
      Message: triggeredSms.message,
      messageName: triggeredSms.messageName,
      OnwardURL: triggeredSms.onwardURL,
      ScheduleDate: calculateCorrectDateWithTimeZone(triggeredSms.schedule),
      AudienceIDs: audienceIDs,
      MetaData: triggeredSms.relatesTo?.length
        ? createRelatesToJSON(triggeredSms.relatesTo)
        : null,
    };

    sendTestSMSBroadcast(api, testSendSMSBody);

    setHasTestSent(true);

    setSendTestSMSBtnText("Sending...");

    setTimeout(() => {
      setSendTestSMSBtnText("Sent");
    }, 2000);
  };

  return {
    appendUrl,
    loading,
    formIsValid,
    createBitlyLink,
    bitlyError,
    formattedUrl,
    handleformattedUrlChange,
    bitlyLoading,
    nextModal,
    prevModal,
    triggeredSms,
    messageLimit,
    messageUnits,
    isGSM,
    handleTriggeredSMSChange,
    handleTextareaChange,
    handleRelatesToChange,
    audienceList,
    selectedAudienceList,
    setSelectedAudienceList,
    onCheckBoxChange,
    fieldPickerList,
    productBrandList,
    productGroupList,
    campaignNameList,
    selectedField,
    selectedFieldList,
    removeSelectedField,
    showFallBackFormPopover,
    handleFallbackFormInputChange,
    setShowFallbackFormPopover,
    selectedProductBrand,
    selectedProductGroup,
    selectedCampaignName,
    setSelectedField,
    setSelectedProductBrand,
    setSelectedProductGroup,
    setSelectedCampaignName,
    appendSelectedField,
    appendSelectedProductBrand,
    appendSelectedProductGroup,
    appendSelectedCampaignName,
    customRelatesToValue,
    customRelatesToInputOnChange,
    appendCustomRelatesToField,
    sendTestSMSToOnChange,
    sendTestSMS,
    sendTestSMSBtnText,
    canSendTest,
    formValidator,
    fieldchange,
    appendtoURL,
    fieldchange_url,
    selectedUrlField,
    UTM_Campaign,
    UTM_Medium,
    UTM_Source,
    appendUTMs,
    setUTM_Source,
    setUTM_Medium,
    setUTM_Campaign,
    UTM_ID,
    setUTM_ID,
    urlFields,
    removefromURL,
    removeUrl,
    pastedUrl,
    setpastedUrl,
  };
};

export default useTriggeredSmsModal;
