import axios from "axios";
import { toast } from "react-toastify";

export const getSMSFields = async (api) => {
  try {
    const response = await api.get(`AudienceSelector/GetSMSFields`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getCampaignNameList = async (api) => {
  try {
    const response = await api.get(`SMSComms/GetRelatesToCampaignName`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProductGroupList = async (api) => {
  try {
    const response = await api.get(`SMSComms/GetRelatesToGroupName`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProductBrandsList = async (api) => {
  try {
    const response = await api.get(`SMSComms/GetRelatesToProductBrand`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProductNamesList = async (api) => {
  try {
    const response = await api.get(`SMSComms/GetRelatesToProductDesc`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const createBitly = async (url) => {
  try {
    const response = await axios.post(
      "https://api-ssl.bitly.com/v4/shorten",
      {
        long_url: url,
        domain: "bit.ly",
        group_guid: "Bm4kba8vHlb",
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
        },
      }
    );

    return response;
  } catch (err) {
    return err.response;
  }
};

export const getBitlyMetrix = async (id) => {
  try {
    const response = await axios.get(
      `https://api-ssl.bitly.com/v4/bitlinks/${id}/clicks?unit=day&units=-1`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
        },
      }
    );

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const broadcastEmail = (body) => {
  try {
    const response = axios.post("/");
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const sendTestSMSBroadcast = async (api, body) => {
  try {
    const response = await api.post("Broadcast/SendTestSMSBroadcast_v2", body);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const postSMSBroadcast = async (name, api, body) => {
  // const responsePromise = api
  //   .post("Broadcast/SendSMSBroadcast", body)
  //   .then((res) => res)
  //   .catch((err) => console.log(err));

  // return responsePromise;

  // let response = api
  //   .post("Broadcast/SendSMSBroadcast", body)
  //   .then((res) => res)
  //   .catch((err) => console.log(err));

  let response = api
    .post("Broadcast/SendSMSBroadcast_v5", body)
    .then((res) => res)
    .catch((err) => console.log(err));

  return toast.promise(response, {
    success: "Success",
    pending: `Creating SMS Broadcast '${name}'`,
    error: `Failed to create SMS Broadcast '${name}'`,
  });
};

export const postBroadcastFile = async (name, api, body) => {

  let response = api
    .post("Broadcast/SendBroadcastFile", body)
    .then((res) => res)
    .catch((err) => console.log(err));

  return toast.promise(response, {
    success: "Success",
    pending: `Creating Broadcast File'${name}'`,
    error: `Failed to create Broadcast File '${name}'`,
  });
};

export const getBroadcasts = async (api) => {
  try {
    const response = await api.get("Broadcast/GetBroadcasts");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getBroadcastDetails = async (campaignId, api) => {
  try {
    const response = await api.get(
      `Broadcast/GetBroadcastDetails/${campaignId}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAudienceMembersSendStatus = async (campaignId, api) => {
  try {
    const response = await api.get(
      `Broadcast/GetAudienceMembersSendStatus/${campaignId}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const setApproveCampaign = async (campaignId, api) => {
  console.log("setApproveCampaign", campaignId);
  try {
    const response = await api.get(`Broadcast/ApproveCampaign/${campaignId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const setDeclineCampaign = async (campaignId, api) => {
  console.log("setDeclineCampaign", campaignId);
  try {
    const response = await api.get(`Broadcast/DeclineCampaign/${campaignId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
