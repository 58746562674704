import { useState, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { horizonOnlineScopes } from "../auth/auth-config/authConfig";
import axios from "axios";

const ApiNew = () => {
  const [api, setApi] = useState();
  const { instance, accounts } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        return;
      }

      const response = await instance
        .acquireTokenSilent({
          ...horizonOnlineScopes,
          account: accounts[0],
        })
        .then((res) => res);

      const axiosBase = axios.create({
        baseURL:
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_LOCAL_HOST
            : "https://webapi.horizon.online:8510/",
        timeout: 0,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${response.accessToken}`,
        },
      });

      setApi({ ...axiosBase });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return { api };
};

export default ApiNew;
