import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { switchModal } from "../../../redux/adhoc-communication/adhoc-communication.reducer";
import { postBroadcastFile } from "../adhocCommunication.service";
import Button from "@material-ui/core/Button";
import ApiNew from "../../../common/api/apinew.service";
import styles from "../sms-confirmation-modal/smsConfirmationModal.module.scss";

const SftpConfirmationModal = ({ closeModal }) => {
  const { api } = ApiNew();

  const dispatch = useDispatch();
  const adhocSms = useSelector(
    ({ adhocCommunication: { adhocSms } }) => adhocSms
  );

  //https://stackoverflow.com/questions/12413243/javascript-date-format-like-iso-but-local
  const calculateCorrectDateWithTimeZone = (dateStr) => {
    const date = new Date(dateStr);

    const fullDT = date.toISOString();

    return fullDT;
  };

  // const selectedAudience = useSelector(({ adhocCommunication: { selectedAudience } }) => selectedAudience)

  const sendSmsBroadcast = async () => {
    //concat selected audience IDs into new Array
    const audienceIDs = adhocSms.selectedAudiences.map((x) => x.id);
    const message = adhocSms.message;

    // check if Message contains {{sms-shortlink}} if false then set onwardurl as null
    let onwardUrl = message.includes("{{sms-sh-link}}") ? adhocSms.onwardURL : null;

    let sendSMSBody = {
      AudienceIDs: audienceIDs,
      MessageName: adhocSms.messageName,
      Message: message,
      ScheduleDate: calculateCorrectDateWithTimeZone(adhocSms.schedule),
      Fields: adhocSms.selectedFields ?? [],
      MetaData: adhocSms.relatesTo?.length
        ? createRelatesToJSON(adhocSms.relatesTo)
        : "[]",
      OnwardURL: onwardUrl
    };

    postBroadcastFile(adhocSms.messageName, api, sendSMSBody);

    closeModal();
  };

  const createRelatesToJSON = (relatesTo) => {
    let obj = {
      customFields: [],
      campaigns: [],
      brands: [],
      groups: [],
    };

    relatesTo.split(" / ").forEach((str) => {
      const strArr = str.split(":");
      let fieldName = strArr[0].toLowerCase();
      let value = strArr[1] ? strArr[1].trim() : "";

      switch (fieldName) {
        case "group":
          obj.groups.push(value);
          break;

        case "custom field":
          obj.customFields.push(value);
          break;

        case "campaign":
          obj.campaigns.push(value);
          break;

        case "brand":
          obj.brands.push(value);
          break;

        default:
          break;
      }
    });

    let relatesToObj = {
      relatesTo: obj,
    };

    return JSON.stringify(relatesToObj);
  };

  return (
    <div className={styles["modal-container"]}>
      <h1 className={styles.header}>Triggered SFTP Confirmation</h1>
      <div className={styles.main}>
        <dl className={styles.dl}>
          <div className={styles.wrapper}>
            <dt className={styles.dt}>Audience</dt>
            <dd className={styles.dd}>
              {adhocSms.selectedAudiences.map((aud) => aud.name).join(", ")}
            </dd>
          </div>

          {/* <div className={styles.wrapper}>
              <dt className={styles.dt}>
                Volume
              </dt>
              <dd className={styles.dd}>
                {selectedAudience?.count}
              </dd>
            </div> */}

          <div className={styles.wrapper}>
            <dt className={styles.dt}>Message Name</dt>
            <dd className={styles.dd}>{adhocSms?.messageName}</dd>
          </div>

          <div className={styles.wrapper}>
            <dt className={styles.dt}>Message</dt>
            <dd className={styles.dd}>{adhocSms?.message}</dd>
          </div>

          <div className={styles.wrapper}>
            <dt className={styles.dt}>Relates To</dt>
            <dd className={styles.dd}>{adhocSms?.relatesTo}</dd>
          </div>

          <div className={styles.wrapper}>
            <dt className={styles.dt}>Schedule</dt>
            <dd className={styles.dd}>
              {new Date(adhocSms?.schedule).toLocaleString()}
            </dd>
          </div>
        </dl>
      </div>

      <div className={styles.actions}>
        <Button
          onClick={() => dispatch(switchModal(5))}
          size="small"
          className={styles.cancel}
        >
          Back
        </Button>
        <Button
          size="small"
          className={styles.next}
          onClick={() => sendSmsBroadcast()}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default SftpConfirmationModal;
